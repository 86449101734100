

.search-bar{
    width: 100%;
    height: calc(60px + 1vmin);
}

.toolbar{
    width: 100%;
    display: flex;
    align-items: justify-content_start;
    position: sticky;
    top: 0;
    z-index: 10;
    flex-direction: column;
}

.toolbar > div.logo-div{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.logo-img{
    height: 32px;
    width: auto;
}

.toolbar > div.menu-div{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    width: 100%;
    text-transform: capitalize;
    height: 48px;
    max-height: 48px;
    min-height: 48px;

}

.menu-voice-div{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: box-shadow .3s ease-in-out !important;
    height: 48px;
}

.menu-voice-div:hover, .menu-item-selected{
    height: 48px;
}

.menu-voice-div > a{
    text-decoration: none;
}

.toolbar .icon-menu{
    display: none;
}

.mobile-menu{
    height: 0;
    overflow-y: hidden;
    max-height: 0;
}

.language-chip-selected{
    z-index: 1;

}

.languages-container{
    position: absolute;
    top: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    border-radius: 20px;

    padding-top: 42px;
    padding-right: 4px;
    padding-left: 4px;
    margin-right: -4px;
    padding-bottom: 4px;
}

.languages-container-expanded{


    transition: all .3s ease-in-out;
    max-height: 500px;
}

.languages-container-condensed{
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
}

.language-chip{
    text-align: center !important;
    min-width: 60px;
    height: 40px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.language-chip-selected-mobile{
    position: absolute;
    top: 0;
    right: 16px;
}


.social-container-toolbar{
    display: flex;
    align-items: center;
}

.languages-container-mobile{
    top: 0 !important;
    right: 16px !important;
    gap: 2px;
}



.menu-voice-div{
    text-decoration: none;
}

.MuiDialogContent-root{
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiDialogTitle-root{
    padding-top: 16px !important;
    padding-bottom: 0 !important;
}

.menu-div>.language-chip, .mobile-menu>.language-chip{
    z-index:1;
}






@media only screen and (max-width: 1024px) {


    .highlights-btns-container{
        flex-direction: column;
    }

    .toolbar{
        padding: 0;
    }

}

/* MOBILE */
@media only screen and (max-width: 768px) {

    .toolbar > div.logo-div{
        height: 64px;
        justify-content: space-between;
    }

    .logo-img{
        height: 32px;
    }

    .hide-mobile{
        display: none !important;
    }

    .flex-row-mobile-column{
        flex-direction: column;
    }

    .large-btn{
        padding: 2vmin 7vmin;
        font-size: calc(14px + 0.5vmin);
        height: 40px;
    }

    .accent-btn{
        height: 40px;
    }

    .two-columns-paragraph{
        column-count: 1;
    }

    .flex-columns{
        flex-direction: column;
        row-gap: 10vmin;
    }

    .height-50 .MuiPaper-root{
        height: 50vh;
    }



    .social-container-toolbar{
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .mobile-menu{
        height: calc(100vh - 64px);
        width: 100vw;
        z-index:99;
        position: fixed;
        transition: all 0.2s ease-in-out;
        display: flex;
        flex-direction: column;

    }

    .mobile-menu-opened{
        max-height: calc(100vh - 64px);
        overflow: auto;
    }

    .mobile-menu-voice-div{
        height: 48px;
        width: 100%;
        border-bottom: 1px solid;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 10vmin 5vmin 10vmin 5vmin;
        text-transform: capitalize;
    }

    .mobile-menu-voice-div:hover{
        opacity: 0.75;
    }

    .mobile-menu-voice-div *{
        text-decoration: none;
    }
    .toolbar{
        justify-content: space-between;
    }

    .logo-img{
        height: 38px;
    }

    .toolbar .icon-menu{
        cursor: pointer;
        display: initial;
    }

    #primary-btns-container .btn{
        min-width: 40px;
    }

}
