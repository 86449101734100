

*{
  --light-grey: #f9f9f3; //var(--light-grey)
  --white: #ffffff; //var(--white)
  --blue: #184069; //var(--blue)
  --light-blue: #C1E2FD; //var(--light-blue)
  --hunter-green: #1d1e06; //var(--hunter-green)
  --pale-olive-light : #f6f6f1; //var(--pale-olive-light)
  --cappuccino: #F3EFD8; //var(--cappuccino)

  --text-color-light: #f9f9f3; //var(--text-color-light)
  --text-color-dark: #2e3126; //var(--text-color-dark)
  --color-icon: #2e3126; //var(--color-icon)
  --gradient-accent: rgba(156, 24, 11, 0.68); // var(--gradient-accent)
  --gradient-accent_2: #2e3126; //var(--gradient-accent_2)
  --color-accent: #28c8a1; //var(--color-accent)
}



.overlay-popup{

  background-color: rgba(0,0,0,0.5);
}

.toolbar > div.menu-div {
  background: var(--blue);
  color: var(--white);
}

.btn,#lists-container-home>.bullet-container:nth-child(even), .scroll-no-overflow {
  background: var(--light-blue);
  color: var(--text-color-dark);
}

.menu-voice-div, #news .font-36-600{
  color: var(--white);
  text-decoration: none;
}

.menu-voice-div:hover, .menu-item-selected{
  color: var(--white);
}

.toolbar{
  backdrop-filter: blur(16px);

}

.toolbar > div.menu-div{

}


.dark-btn{
  background: var(--hunter-green);
  color: var(--text-color-light);
}

.accent-btn, {
  background: var(--color-accent);
  color: var(--text-color-light);
}

.swiper-pagination-bullet-active{
  background: var(--color-accent) !important;
}

.primary-btn{
  background: var(--light-grey);
  color: var(--text-color-light);
}

.header-home-div > div > p.title, .header-home-div > div > p.subtitle{
  color: var(--text-color-light);
}

.accent-text{
  color: var(--light-grey);
}

.contacts-container-div, .popup-container{
  background: var(--white);
}

.gallery-title,.contacts-container-div > .title{
  color: var(--hunter-green);
}

.contacts-container-div > .subtitle{
  color: var(--light-grey);
}

.gallery-title-red{
  color: var(--gradient-accent_2);
}

.gallery-subtitle{
  color: var(--light-grey);
}

.categories-labels a{
  color: var(--light-grey);
}

.header-home-div:after{
  background: linear-gradient(to top, rgba(255,0,0,0) 0%, var(--light-grey) 100%);
}

@keyframes photoSlide {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 10%;
  }
}

::selection {
  color: var(--text-color-light); /* WebKit/Blink Browsers */
  background: var(--hunter-green);
}
::-moz-selection {
  color: var(--text-color-light);
  background: var(--hunter-green);
}

.icon{
  color: var(--color-icon);
}


*::-webkit-scrollbar-thumb {
  background-color: var(--light-grey);
}

.contact-us-div{
  background-color: var(--light-grey);
}

.contact-description{
  color: var(--text-color-light);
}

.swiper-pagination-bullet{
  background: var(--white);
  opacity: 1;
  -webkit-box-shadow: 1px 2px 8px -2px #000000;
  box-shadow: 1px 2px 8px -2px #000000;
}

.swiper-pagination-bullet-active{
  background-color: var(--light-grey);
}

.whitebox-info{
  background: var(--white);
}

.whitebox-info *{
  color: var(--hunter-green);
}

.info-header, .info-header *{
  color: var(--light-grey);
}

.newsletter-div{
  background-color: var(--hunter-green);
}

.contacts-div {
  background-color: var(--hunter-green);
  color: var(--text-color-light);
}

.contacts-div *{
  color: var(--text-color-light);
}


.news-description{
  color: var(--light-grey);
}

.input{
  background: var(--white);
}

.check-div{
  color: var(--text-color-light);
}


.contacts-detail{

}

.checkbox{
  background: transparent;
}

.label-filter{
  color: var(--hunter-green);
}

.sub-categories-labels-container{
  background-color:rgba(#eee,0.8);
}

.selected-category{
  color: var(--hunter-green) !important;
}

.white-btn{
  background: var(--white);
  color: var(--text-color-dark);
}

.contact-span{
  color: var(--hunter-green);
}

.dialog-title{
  color: var(--hunter-green);
}

.MuiOutlinedInput-notchedOutline{
  border-color: var(--light-grey) !important;
  border-width: 1px !important;
}

.Mui-focused{
  color: var(--light-grey) !important;
}

.MuiIconButton-root{
  color: var(--light-grey) !important;
}

.MuiSvgIcon-root{
  color: var(--light-grey) !important;
}

.dialog-ok-button{
  background-color: var(--hunter-green);
  color: #fff;
}

.close-dialog-label{
  color: var(--light-grey)
}

.label-filter .MuiFormControlLabel-label, .checkbox-label-filter .MuiFormControlLabel-label{
  color: var(--light-grey)
}

.link{
  color: var(--light-grey);
  font-weight: bold;
}

.link:hover{
  color: var(--hunter-green)
}

.square-containers-header{
  background: var(--white);
}

.square-containers-header .gallery-title{
  color: var(--color-accent);
}

.arrow{
  color: var(--light-grey) !important;
}

div.notFound{
  color: var(--hunter-green);
}


.toolbar .icon-menu{
  color: var(--hunter-green);
  font-size: 2em;
}

.mobile-menu{
  background-color: var(--white);
  color: var(--text-color-light);
}

.mobile-menu-voice-div{
  border-color: var(--hunter-green);
  color: var(--hunter-green);
}

.mobile-menu{
  border-color: var(--hunter-green);
}

.imgtext-div:nth-child(even) > .action-container-div > .desc-div, #primary-btns-container>button:last-child{
  background: var(--light-blue);
  color: var(--text-color-dark);
}

.imgtext-div:nth-child(even) > .action-container-div > .action-div, #secondary-btns-container .btn{
  background: var(--light-blue);
  color: var(--text-color-dark)
}

.menu-item-selected{
  color: var(--light-blue);
    box-shadow: inset 0 -3px 0 var(--light-blue);
}



.imgtext-div:nth-child(odd) > .action-container-div > .action-div{
  background: var(--blue);
}


/* Track */
::-webkit-scrollbar-track {
  background: var(--hunter-green);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--hunter-green);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--hunter-green);
}

.container{
  background: var(--light-grey);
}

.tab-header > div{
  background: var(--white);
  color: var(--text-color-dark);
}

.tab-header > div.tab-products:hover{
  background: var(--light-blue);
}

.background-light{
  background: var(--light-grey);
}

.info-container-div{
  background: var(--hunter-green);
}

.info-container-div > div.info-div > p{
  color: white;
}


.icon-white{
  color: white;
}

.mail-to-color{
  color:#27c8a1;
}

.action-div{
  background: var(--blue);
}

.action-div-dark{
  background: var(--hunter-green);
  color: white;
}

.customer-container-div{
  background: var(--blue);
}

#news{
  background: var(--blue);
}

#contacts{
  background: var(--blue);
}

#contacts{
  color: var(--white);
}

#contacts *{
  color: var(--white);
}

.news-container-div{
  background: var(--blue);
}

.news-container-div .news-title, .news-container-div .news-subtitle{
  color: var(--white);
}


.news-cell-div:hover, #software-categories, #servizi-gallery{
  background-color: var(--white);
}

.news-cell-div,  .customer-service-support-flex-container .software-cell-div:hover{
  background: white;
}

.news-div{
  background: var(--blue);
}

.gallery-container, #b2c-slider{
  background: var(--hunter-green);
}


.news-date, #news-page .intro-test-p, #numbers-list, .back-btn > *, #b2c-slider .about-titles, #contact-page .intro-test-p,
.toolbar .icon-menu{
  color: var(--light-blue);
}

.mobile-menu-voice-div{
  color: var(--hunter-green);
  border-color: var(--light-blue) !important;
  text-decoration: none;
  outline: none;
}

.back-bar-div{
  background: var(--blue);
}

.info-software-div,.info-news-div{
  background: white;
}

.news-background{
  background: var(--blue);
}

.news-detail-div{
  background: var(--blue);
}

.news-detail-div > div{
  background: white;
}


/* TABLET */
@media only screen and (max-width: 768px) {

  .menu-item-selected {
    box-shadow: initial;
    font-weight: bold;
  }

}
