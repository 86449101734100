@import "./assets/fonts/Silka/Silka-Roman-Webfont/stylesheet.css";
@import "./assets/fonts/Silka-Mono/roman-webfontkit/stylesheet.css";
@import "animate.css";


body {
  margin: 0;
  font-family: "silkaregular",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html{
  overflow-x: hidden;
}

b{
  font-family: 'silkabold';
}

*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline:none !important;
  -webkit-tap-highlight-color: transparent;
  font-family: "silkaregular",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

}

a{
  text-decoration: none;
  cursor: pointer;
}

a:hover{
  opacity: 0.8;
}

button a {
  height: 100%;
  width: 100%;
}

::selection {
  background: #aef0d6cc; /* WebKit/Blink Browsers */
  color: white;
}
::-moz-selection {
  background: #aef0d6cc;
  color: white;
}



#perche, #chi, #news, #cambiare-gestionale, #caratteristiche, #funzionalita, #ecosistema{
  scroll-margin-top: 12vmin;
}



#perche img{
  width: 100%;
  border-radius: 12px;
  margin-top: 12vmin;
}

.two-columns-paragraph {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 5vmin;
  column-gap: 5vmin;
  line-height: 1.5;
  font-size:calc(12px + 1vmin);
  margin-bottom: 12vmin;
  margin-top: 6vmin;
}

.btn-container{
  text-align: center;
}



.trial-title-div{
  font-family: silkamedium;
}

.text-accent{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #3cb470;
}



.margined{
  margin-right: 8vmin;
  margin-left: 8vmin;
}

.background {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 80%;
  height: auto;
  aspect-ratio: 4;
}

.kreosoft-logo{
  background-image: url(/src/assets/images/logo_blu_kreosoft.svg);
  height: 52px;
    width: 135px;
}



.regular-font{
  font-size: calc(12px + 1vmin);
  line-height: 1.5;
  letter-spacing: normal;
  color: #5f6360;
  font-weight: normal;
}

.regular-font-smaller, .regular-font-smaller *{
  font-size: calc(10px + 0.9vmin) !important;
}

.align-left{
  text-align: left;
}

.flex-vertical-container{
  gap: 10vmin;
  display: flex;
  flex-direction: column;
}

.btn{
  transition: border-radius .2s ease-in-out;
}

.btn:hover{
  border-radius: 0;
}

@media only screen and (max-width: 1024px) {

  .margined{
    margin-right: 4vmin;
    margin-left: 4vmin;
  }

}


.action-div{
  max-width: 200px;
  min-width: 115px;
  padding: 1.5vmin;
  border-radius: 12px;
  height: 5vmin;
}



.gallery {
  display: flex;
  overflow-x: auto;
  cursor: url(assets/images/hold_drag.svg), auto;
  padding-top: 4vmin;
  padding-bottom: 4vmin;
  gap:3vmin;
  justify-content: left;
  flex-wrap: wrap;
}




#news .title{
  margin-bottom: 5vmin;
}

#news .gallery-item{
  background-color: white;
  max-width: 45vmin;
  margin-left: 10vmin;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

#news .gallery-item:first-child{
  margin-left: 0;
}

#news .titoletto{
  font-size: calc(9px + 0.5vmin);
  color: #cacf9d;
  text-align: left;
}

#news .left-title{
  font-size:  calc(16px + 1.4vmin);
  font-weight: 500;
  color: #0e2132;
  text-align: left;
  margin-top: 3vmin;
}

#news .sottotitolo{
  font-size: calc(11px + 0.8vmin);
  line-height: 1.43;
  color: #5f6360;
  text-align: left;
  margin-top: 5vmin;
}

#news .left-text{
  color: #bfbfbf;
  text-align: left;
  width: 100%;
  margin-top: 3vmin;
  margin-bottom: 3vmin;
  line-height: 1.4;
}

#news img{
  margin: 0;
  width: 100%;
  height: auto;
  border-radius: 12px;
}

#news .gallery-item:hover {
  transform: initial;
}

.gallery-item-text-container{
  padding: 24px;
}

.gallery-item-detail{
  height: auto;
  display: inline-flex;
  background-position: top;
  border-radius: 24px;
}

.gallery-detail{
  height: 55vmin;
  display: flex;
  overflow-x: auto;
  gap: 20px;
}

.swiper-wrapper{
  gap: 1px;
}





/* TABLET */
@media only screen and (max-width: 768px) {



  .news-cell-div{
    width: 100%;
  }

  .action-div{
    height: 7vmin;
    padding: 1.8vmin;
  }

}




/* MOBILE */
@media only screen and (max-width: 580px) {
  .regular-font{
    font-size: calc(11px + 1vmin);
  }
  .hide-mobile{
    display: none !important;
  }
}

